.detectBookingSection {
  text-align: center;
  justify-self: center;
  align-self: center;
  max-width: 800px;
}

.detectBookingPage {
  padding: 10px 0 30px 0;
  background-color: #00142e;
  width: 100%;
  height: 100vh;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: center;
}

.optionBox {
  background-color: white;
  color: #00142e;
  margin-top: 20px;
  padding: 5px;
  border-radius: 8px;
  overflow-y: scroll;
}

.toggleSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.toggleSection > div:hover {
  cursor: pointer;
}

.hasBooking {
  padding: 8px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  justify-content: center;
  align-items: center;
}

.hasBooking.active {
  background-color: deepskyblue;
}

.hasBooking p,
.noBooking p {
  margin: 0;
}

.noBooking {
  padding: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  justify-content: center;
  align-items: center;
}

.noBooking:not(:active) {
  color: #00142e;
}

.noBooking.active {
  background-color: #00142e;
  color: white;
}

.darkBorder.hasBooking {
  border-left: 2px solid black;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.darkBorder.noBooking {
  border-right: 2px solid black;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.MuiAutocomplete-root {
  background: white;
  padding: 40px;
  border-radius: 8px;
}

.detectBookingSection
  .MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-endAdornment {
  right: 9px !important;
}

.hasBookingPage {
  max-width: 450px;
}

.jobInput {
  width: 70%;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 22px;
  border: 1px solid #00142e;
}

.bookingDetailsSection {
  padding: 20px;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexRow h6 {
  margin: 0;
  padding-right: 10px;
}

.flexRow p {
  margin: 0;
}

.vehicleDetailModalContent {
  text-align: center;
}

.vehicleDetailSection .flexRow {
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.vehicleDetailSection .flexRow h6 {
  font-size: 18px;
}

.pricingPage {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.selectLocationInfoSection {
  width: 100%;
  min-height: 500px;
}

.locationInputSection {
  padding: 10px 0 60px 0;
  background-color: #00142e;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.locationInputSection h1 {
  margin-bottom: 40px;
  font-size: 44px;
  text-transform: uppercase;
}

.pricingPage .MuiAutocomplete-root {
  width: 480px !important;
}

.pricingPage .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 18 !important;
  background-color: white !important;
  border-radius: 30px !important;
}

.pricingPage .MuiInputLabel-root.Mui-focused,
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
  margin-top: -10px;
}

.pricingPage .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.pricingPage
  .MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-endAdornment {
  right: 22% !important;
}

.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 7.5px 90px 7.5px 6px !important;
}

.locationViewButton {
  background-color: #00a0d2;
  position: absolute;
  bottom: 0;
  height: 56px;
  width: 100px;
  text-align: center;
  border-radius: 0 30px 30px 0;
  right: 0;
  cursor: pointer;
}

.inputArea {
  position: relative;
}

.locationViewButton p {
  margin-top: 18px;
}

.pricingArea {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.pricingDetailSection {
  padding: 30px 0 40px 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.optionBox .serviceTypeOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.cardOption {
  padding: 20px;
  color: #b1b1b1;
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
}

.activeCard {
  padding: 20px;
  background-color: #00a0d2;
  color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
}

.cardOptionImage {
  filter: sepia(0) saturate(0) brightness(75%) hue-rotate(180deg);
  width: 70px;
  height: 50px;
  object-fit: contain;
  margin: auto;
  margin-bottom: 30px;
}

.activeCard .cardOptionImage {
  filter: brightness(0) invert(1);
}

.cardOptionTitle {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.optionBox h2 {
  color: #001571;
  font-size: 22px;
  text-align: center;
  font-weight: 500;
}

.pricingSection p {
  color: #b1b1b1;
  text-align: center;
  margin-top: -10px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
}

.toggleArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.firstTimeText {
  padding-top: 25px;
  margin-right: 20px;
}

.secondTimeText {
  padding-top: 25px;
  margin-left: 20px;
}

.pricingSection {
  width: 100%;
}

.pricingSection p.textActive {
  color: #00a0d2;
}

.pricingGrid {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 70px;
}

.pricingCard {
  align-items: center;
  border: 1px solid #00a0d2;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-width: 200px;
  padding: 20px;
  margin-right: 15px;
  margin-bottom: 15px;
  width: 300px;
}

.pricingCardHeading {
  margin: 0;
  margin-bottom: 10px;
}

.optionBox .pricingCardText,
.optionBox .pricingCardPOA {
  color: #00a0d2;
  margin: 0;
  font-weight: 500;
}

.bookNowButton {
  background-color: #00a0d2;
  padding: 10px;
  color: white;
  margin-bottom: 50px;
  min-width: 150px;
  text-align: center;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 600;
}

.pricingCardText::before {
  content: "From ";
}

.originalPrice {
  color: #a9a9a9;
  text-decoration: line-through;
}

.pricingSection p.smallDesc {
  color: #001571;
}

.pricingSection p.includedText {
  margin: 0;
  margin-top: 10px;
  text-align: left;
  width: 100%;
  padding-left: 15px;
  color: #00a0d2;
}

.includedItemsList {
  width: 80%;
  padding-left: 15px;
  margin-top: 10px;
}

.includedItem {
  color: #9c9c9c;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.optionalExtrasSection {
  width: 100%;
  min-height: 320px;
  background-color: #f8f8f8;
  padding: 30px 0;
}

.optionalExtrasSection h2 {
  text-align: center;
}

.extrasSectionArea {
  max-width: 700px;
  margin: auto;
}

.extrasSectionList {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  margin-top: 60px;
}

.extraItems {
  margin-bottom: 30px;
}

.optionBox .serviceStep {
  display: flex;
  flex-direction: column;
  max-height: 75vh;
}

.optionBox .bookingPriceGrid {
  margin: 20px auto;
  display: flex;
  width: 85%;
  max-width: 1200px;
}

.optionBox .serviceStep h3 {
  color: #001571;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 10px;
}

.optionBox .bookingPriceGrid .pricingCard {
  position: relative;
  border-color: #9c9c9c;
  min-width: 230px;
  min-height: 180px;
}

.optionBox .bookingPriceGrid .pricingCard.selectedService {
  border-color: #00a0d2;
  border-width: 2px;
}

.optionBox .bookingPriceGrid .pricingCard h2 {
  margin: 0;
}

.optionBox .bookingPriceSelect {
  margin-top: 30px;
  margin-bottom: 0;
  min-width: 150px;
  text-align: center;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 600;
  padding: 8px;
}

.optionBox .bookingPriceGrid .bookNowButton {
  margin-bottom: 0;
  width: 175px;
}

.optionBox .serviceDurationSection {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 0;
}

.optionBox .serviceDuration {
  margin-left: 12px;
  margin-bottom: 0;
}

.optionBox .durationIcon {
  width: 18px;
}

.optionBox .bookingPriceGrid .includedText {
  align-self: flex-start;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

.extraJobStep {
  display: flex;
  flex-direction: column;
  max-height: 75vh;
  overflow: scroll;
  padding-left: 15px;
  padding-right: 15px;
}

.extraJobStep h2 {
  text-align: left;
  margin-bottom: 30px;
}

.extraJobStep h2.notFirstHeading {
  margin-top: 30px;
}

.extraJobStep .extraItem {
  display: flex;
  flex-direction: row;
  border: 1px solid #bcbcbc;
  border-radius: 4px;
  padding: 10px 20px;
  margin-bottom: 25px;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
  cursor: pointer;
  position: relative;
}

.confirmationJobStep {
  max-height: 75vh;
  overflow: scroll;
  padding-left: 20px;
  padding-right: 20px;
}

.confirmationJobStep .aboutYouSection {
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.confirmationJobStep .oneColumn {
  margin-right: 0;
}

.detectBookingSection .confirmSection {
  max-width: 100%;
}

.detectBookingSection .confirmSection .buttonSection .lightBlueButton {
  width: 100%;
  margin-left: 0;
}

.detectBookingSection .buttonSection {
  padding-bottom: 30px;
}

.prevButton {
  background-color: #fff;
  border: 1px solid #001571;
  border-radius: 40px;
  color: #001571;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 0;
  width: 175px;
}

.jobBookingFlow {
  padding-left: 20px;
  padding-right: 20px;
}

.jobBookingFlow .oneColumn {
  margin: 0;
}

.jobBookingFlow .jobInput {
  width: 70%;
  margin: 10px auto;
  text-align: center;
}

.getCarDetails {
  background-color: #00a0d2 !important;
  border: 1px solid #00a0d2 !important;
  border-radius: 40px !important;
  color: #fff !important;
  cursor: pointer !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 10px 12px !important;
  margin-left: 10px !important;
}

.buttonSection {
  margin-top: 20px;
  margin-bottom: 20px;
}

.absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00142e;
}

.absolute .optionBox {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: #fff;
  max-width: 600px;
}

.confirmJobHeading {
  margin-top: 20px;
}

.confirmationNote {
  margin: auto;
  max-width: 350px;
}

.pickupTimeSelection {
  width: 100px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.pickupTimeSelection .stp--select {
  font-size: 20px;
}

.alignCenter {
  align-items: center;
}

.checkUserPickupTime {
  text-align: center;
}

.confirmSection .buttonSection {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.extraJobStep .prevButton,
.confirmSection .prevButton {
  width: 100%;
  margin-bottom: 15px;
}

.react-datepicker {
  font-size: 18px !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 35px !important;
}

.react-datepicker__time-container {
  width: 175px !important;
}

.mustHave::before {
  content: "Must Have!";
  position: absolute;
  top: -12px;
  background-color: #00a0d2;
  padding: 5px 40px;
  color: white;
  font-weight: 600;
  border-radius: 4px;
  left: 25%;
}

@media (max-width: 767px) {
  .pricingCard {
    min-width: 310px;
  }
  .extrasSectionList {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
  .extrasSectionArea {
    max-width: 800px;
    margin: 0 auto;
    width: 320px;
  }
  .vehicleDetailModalContent {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 420px;
  }
  .optionBox .bookingPriceGrid {
    display: flex;
    flex-direction: column;
  }
  .cardOption {
    padding: 10px;
  }
  .activeCard {
    padding: 10px;
  }
  .cardOptionImage {
    height: 30px;
    margin-bottom: 10px;
  }
  .optionBox {
    width: 370px;
  }
  .extraContentWrapper {
    flex-direction: column;
  }
  .extraItem div.extraName {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .jobBookingFlow .buttonSection {
    flex-direction: column-reverse;
    display: flex;
    justify-content: space-between;
    height: 150px;
  }

  .jobBookingFlow .buttonSection button {
    width: 100%;
    margin-left: 0 !important;
  }
}

@media (min-width: 768px) {
  .pricingGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .pricingGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) {
  .pricingGrid {
    grid-template-columns: repeat(4, 1fr);
  }
}
